/* eslint-disable unicorn/prefer-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnv } from 'src/context/EnvProvider';
import { useUser } from 'src/context/UserProvider';
import { Assembly } from 'src/services/assemblies';
import {
  Constraint,
  getConstraintsOfPieces,
  getModificationsOfPieces,
  Modification,
} from 'src/services/configurator';
import { getGranits, Granit } from 'src/services/granits';
import {
  compare2Float32Array,
  compare2Point,
  float32ToPoint,
  getIndexOfFloat32Array,
  getModificationWithHistory,
  getMultiMovingAxisOf2Point,
  getPointHistoryName,
  getReverseHistoryName,
  isAccesory,
  isPointBigger,
  movePieceWithPieceDeltaAndAxis,
  pointToFloat32,
  updateBoundingBox,
  updtateGeometryWithCoord,
} from 'src/utils/configurator.utils';
import { changePlatingShape } from 'src/utils/plating.utils';
import { changeSteleShape, getSteleExtrudeSettings } from 'src/utils/stele.utils';
import * as THREE from 'three';
import { Box3, Shape, Vector3, WebGLRenderer } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';

import { getAdminEngravingsPrice } from 'src/services/coefficients';
import { getDefaultTextureSetup } from 'src/utils/piece';
import Decimal from 'decimal.js';
import { PieceType } from '../services/pieces';
import { useImportPiece } from './useImportPiece';
import useLoadDataList from './useLoadDataList';
import useLoadData from './useLoadData';

export type PieceDetail = {
  name: string;
  size: number;
  ttcPrice: number;
  htPrice: number;
  purchasePrice: number;
  type: PieceType;
  x: number;
  y: number;
  z: number;
  granit?: Granit;
  underAssemblyUUID?: string;
  geometry: any;
  position: Vector3;
};

export const useConfigurator = (
  assembly: Assembly | null,
  orbitRef: React.MutableRefObject<any>,
  gl: WebGLRenderer | undefined,
) => {
  const { buckets } = useEnv();
  const { user } = useUser();
  const { data: adminEngravingsPrice } = useLoadData(() => getAdminEngravingsPrice());

  const [customSteles, setCustomSteles] = useState<any[]>([]);
  const [customPlatings, setCustomPlatings] = useState<any[]>([]);
  const [activePieces, setActivePieces] = useState<any[]>([]);

  const [patterns, setPatterns] = useState<{ [key: string]: { [key: string]: any }[] }>({});

  const [pieceIndex, setPieceIndex] = useState<number>(0);
  const [history, setHistory] = useState<{
    [k: string]: {
      [k: string]: Float32Array;
    };
  }>({});
  const [originalGeometry, setOriginalGeometry] = useState<any>();

  useEffect(() => {
    const loader = new THREE.TextureLoader();
    const assemblyPieces = assembly?.pieces?.map((el) => {
      const defaultGranitLink = `${buckets?.BUCKET_3D_PIECES || ''}/${el.granit?.image}`;
      const loadedTexture = loader.load(defaultGranitLink);
      loadedTexture.wrapS = THREE.RepeatWrapping;
      loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
      // loadedTexture.offset.x = 0.5;
      loadedTexture.repeat.x = 1;

      const defaultPatterns = getDefaultTextureSetup(el.piece, buckets);

      return {
        uuid: generateUUID(),
        ...el.piece,
        position: el.position,
        newGeometry: el.newGeometry,
        defaultPatterns,
        texture: loadedTexture,
        granit: el.granit,
        fabrications: el.piece.pieceFabrication?.map((el: any) => el?.fabrication),
        patterns: el.patterns,
        isBlocked: el.isBlocked,
      };
    });

    const getRotation = (orientation: string) => {
      if (orientation.includes('y')) {
        return new THREE.Euler(0, orientation.includes('inverse') ? Math.PI : 0, 0);
      }
      return new THREE.Euler(0, 0, 0);
    };

    const newPatterns = { ...patterns };
    if (assemblyPieces) {
      for (const element of assemblyPieces) {
        for (const el of element.patterns) {
          if (!element.uuid) return;
          if (!newPatterns[element.uuid]) {
            newPatterns[element.uuid] = [];
          }

          if (!newPatterns[element.uuid][el.orientation]) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const imageObj = new Image();
            imageObj.src = el.image;

            // eslint-disable-next-line unicorn/prefer-add-event-listener
            imageObj.onload = () => {
              canvas.width = imageObj.width;
              canvas.height = imageObj.height;
              if (context) context.drawImage(imageObj, 0, 0);
            };

            const newCamera = new THREE.PerspectiveCamera();
            newCamera.matrix.fromArray(JSON.parse(el.camera));
            newCamera.matrix.decompose(newCamera.position, newCamera.quaternion, newCamera.scale);

            newPatterns[element.uuid][el.orientation] = {
              id: el.orientation,
              texture: new THREE.CanvasTexture(canvas),
              elements: [],
              camera: newCamera,
            };
          }

          const getNumberParseFloat = (value: any) => {
            return Number.parseFloat(value as string);
          };

          // eslint-disable-next-line unicorn/prefer-add-event-listener
          newPatterns[element.uuid][el.orientation].elements?.push({
            uuid: generateUUID(),
            patternId: el.pattern?.id,
            imageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.image}`,
            reducedImageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.reducedImage}`,
            pieceUUID: element.uuid,
            orientation: el.orientation,
            position: new Vector3(el.positionX, el.positionY, 0),
            rotation: getRotation(el.orientation),
            scale: new THREE.Vector2(el.scaleX, el.scaleY),
            inverse_horizontal: false,
            inverse_vertical: false,
            name: el.pattern?.name,
            type: el.type,
            color: el.color,
            fontFamily: el.fontFamily,
            fontSize: el.fontSize,
            letterSpacing: String(el.letterSpacing),
            text: el.text,
            align: el.align,
            description: el.pattern?.description,
            default: new THREE.Vector2(
              getNumberParseFloat(
                Math.round(
                  (el.pattern?.height ?? 1) * (Number.parseFloat(String(el.pattern?.ratio)) ?? 1),
                ),
              ),
              getNumberParseFloat(el.pattern?.height),
            ),
            price: el.pattern?.price,
            leaderGranitEngraving: el.pattern?.leaderGranitEngraving,
            user: el.pattern?.user,
          });
        }
      }
    }

    setPatterns(newPatterns);

    const underAssembliesPieces = [];

    if (assembly?.underAssemblyOnAssembly)
      for (const el of assembly.underAssemblyOnAssembly) {
        const underAssembly = {
          id: el.underAssembly.id,
          uuid: generateUUID(),
          name: el.underAssembly.name,
          modifications: el.underAssembly.modificationOnUnderAssembly.map((element) => {
            return { ...element.modification, type: element.type };
          }),
          fabrications: el.underAssembly.UnderAssemblyFabrication.map(
            (fabrication) => fabrication.fabrication,
          ),
        };
        for (const element of el.underAssembly.pieceOnUnderAssembly) {
          const underAssemblyOnAssemblyDetailsPiece = el.underAssemblyOnAssemblyDetailsPiece.find(
            (details) => {
              if (details.pieceOnUnderAssemblyId === element.id) return true;
              return false;
            },
          );

          const defaultGranitLink = `${buckets?.BUCKET_3D_PIECES || ''}/${
            underAssemblyOnAssemblyDetailsPiece?.granit?.image
          }`;
          const loadedTexture = loader.load(defaultGranitLink);
          loadedTexture.wrapS = THREE.RepeatWrapping;
          loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
          // loadedTexture.offset.x = 0.5;
          loadedTexture.repeat.x = 1;

          const defaultPatterns = getDefaultTextureSetup(element.piece, buckets);

          const piece = {
            uuid: generateUUID(),
            ...element.piece,
            defaultPatterns,
            isBlocked: underAssemblyOnAssemblyDetailsPiece?.isBlocked ?? false,
            position: underAssemblyOnAssemblyDetailsPiece?.position,
            underAssembly,
            newGeometry: underAssemblyOnAssemblyDetailsPiece?.newGeometry,
            pieceOnUnderAssemblyId: element.id,
            granit: underAssemblyOnAssemblyDetailsPiece?.granit,
            texture: loadedTexture,
            fabrications: element.piece.pieceFabrication?.map((el: any) => el?.fabrication),
          };

          if (underAssemblyOnAssemblyDetailsPiece) {
            for (const el of underAssemblyOnAssemblyDetailsPiece.patterns) {
              if (!newPatterns[piece.uuid]) {
                newPatterns[piece.uuid] = [];
              }

              if (!newPatterns[piece.uuid][el.orientation]) {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const imageObj = new Image();
                imageObj.src = el.image;

                // eslint-disable-next-line unicorn/prefer-add-event-listener
                imageObj.onload = () => {
                  canvas.width = imageObj.width;
                  canvas.height = imageObj.height;
                  if (context) context.drawImage(imageObj, 0, 0);
                };

                const newCamera = new THREE.PerspectiveCamera();
                newCamera.matrix.fromArray(JSON.parse(el.camera));
                newCamera.matrix.decompose(
                  newCamera.position,
                  newCamera.quaternion,
                  newCamera.scale,
                );

                newPatterns[piece.uuid][el.orientation] = {
                  id: el.orientation,
                  texture: new THREE.CanvasTexture(canvas),
                  elements: [],
                  camera: newCamera,
                };
              }

              const getNumberParseFloat = (value: any) => {
                return Number.parseFloat(value as string);
              };

              // eslint-disable-next-line unicorn/prefer-add-event-listener
              newPatterns[piece.uuid][el.orientation].elements?.push({
                uuid: generateUUID(),
                patternId: el.pattern?.id,
                imageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.image}`,
                reducedImageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.reducedImage}`,
                pieceUUID: piece.uuid,
                orientation: el.orientation,
                position: new Vector3(el.positionX, el.positionY, 0),
                rotation: getRotation(el.orientation),
                scale: new THREE.Vector2(el.scaleX, el.scaleY),
                inverse_horizontal: false,
                inverse_vertical: false,
                name: el.pattern?.name,
                type: el.type,
                color: el.color,
                fontFamily: el.fontFamily,
                fontSize: el.fontSize,
                letterSpacing: String(el.letterSpacing),
                text: el.text,
                align: el.align,
                description: el.pattern?.description,
                default: new THREE.Vector2(
                  getNumberParseFloat(
                    Math.round(
                      (el.pattern?.height ?? 1) *
                        (Number.parseFloat(String(el.pattern?.ratio)) ?? 1),
                    ),
                  ),
                  getNumberParseFloat(el.pattern?.height),
                ),
                price: el.pattern?.price,
                leaderGranitEngraving: el.pattern?.leaderGranitEngraving,
                user: el.pattern?.user,
              });
            }
          }
          underAssembliesPieces.push(piece);
        }
      }

    const activesPieces = [...underAssembliesPieces, ...(assemblyPieces ?? [])];

    setActivePieces(activesPieces);

    const formatedSteles = assembly?.steles.map((steleOnAssembly) => {
      const defaultGranitLink = `${buckets?.BUCKET_3D_PIECES || ''}/${
        steleOnAssembly.granit?.image
      }`;
      const loadedTexture = loader.load(defaultGranitLink);
      loadedTexture.wrapS = THREE.RepeatWrapping;
      loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
      // loadedTexture.offset.x = 0.5;
      loadedTexture.repeat.x = 1;

      const shape = new Shape();
      const extrudeSettings = getSteleExtrudeSettings(steleOnAssembly.stele.depth);
      changeSteleShape(
        shape,
        steleOnAssembly.stele.boxLeftHeight,
        steleOnAssembly.stele.boxRightHeight,
        steleOnAssembly.stele.boxHeight,
        steleOnAssembly.stele.boxUpWidth,
        steleOnAssembly.stele.boxDownWidth,
        steleOnAssembly.stele.headIndex,
        steleOnAssembly.stele.baseIndex,
      );

      return {
        id: generateUUID(),
        texture: loadedTexture,
        granit: steleOnAssembly.granit,
        boxLeftHeight: steleOnAssembly.stele.boxLeftHeight,
        boxRightHeight: steleOnAssembly.stele.boxRightHeight,
        boxHeight: steleOnAssembly.stele.boxHeight,
        boxUpWidth: steleOnAssembly.stele.boxUpWidth,
        boxDownWidth: steleOnAssembly.stele.boxDownWidth,
        headIndex: steleOnAssembly.stele.headIndex,
        baseIndex: steleOnAssembly.stele.baseIndex,
        depth: steleOnAssembly.stele.depth,
        isBlocked: steleOnAssembly.isBlocked,
        shape,
        extrudeSettings,
        position: new Vector3(
          steleOnAssembly.position.x,
          steleOnAssembly.position.y,
          steleOnAssembly.position.z,
        ),
        patterns: steleOnAssembly.patterns,
        additionalPriceForCustomStele: steleOnAssembly.additionalPriceForCustomStele,
      };
    });

    if (formatedSteles) {
      for (const element of formatedSteles) {
        for (const el of element.patterns) {
          if (!element.id) return;
          if (!newPatterns[element.id]) {
            newPatterns[element.id] = [];
          }

          if (!newPatterns[element.id][el.orientation]) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const imageObj = new Image();
            imageObj.src = el.image;

            // eslint-disable-next-line unicorn/prefer-add-event-listener
            imageObj.onload = () => {
              canvas.width = imageObj.width;
              canvas.height = imageObj.height;
              if (context) context.drawImage(imageObj, 0, 0);
            };

            const newCamera = new THREE.PerspectiveCamera();
            newCamera.matrix.fromArray(JSON.parse(el.camera));
            newCamera.matrix.decompose(newCamera.position, newCamera.quaternion, newCamera.scale);

            newPatterns[element.id][el.orientation] = {
              id: el.orientation,
              texture: new THREE.CanvasTexture(canvas),
              elements: [],
              camera: newCamera,
            };
          }

          const getNumberParseFloat = (value: any) => {
            return Number.parseFloat(value as string);
          };

          newPatterns[element.id][el.orientation].elements?.push({
            uuid: generateUUID(),
            patternId: el.pattern?.id,
            imageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.image}`,
            reducedImageUrl: `${buckets?.BUCKET_3D_PIECES || ''}/${el.pattern?.reducedImage}`,
            pieceUUID: element.id,
            orientation: el.orientation,
            position: new Vector3(el.positionX, el.positionY, 0),
            rotation: getRotation(el.orientation),
            scale: new THREE.Vector2(el.scaleX, el.scaleY),
            inverse_horizontal: false,
            inverse_vertical: false,
            name: el.pattern?.name,
            type: el.type,
            color: el.color,
            fontFamily: el.fontFamily,
            fontSize: el.fontSize,
            letterSpacing: String(el.letterSpacing),
            text: el.text,
            align: el.align,
            description: el.pattern?.description,
            default: new THREE.Vector2(
              getNumberParseFloat(
                Math.round(
                  (el.pattern?.height ?? 1) * (Number.parseFloat(String(el.pattern?.ratio)) ?? 1),
                ),
              ),
              getNumberParseFloat(el.pattern?.height),
            ),
            price: el.pattern?.price,
            user: el.pattern?.user,
          });
        }
      }
    }

    setCustomSteles(formatedSteles ?? []);

    const formatedPlating = assembly?.platings.map((platingOnAssembly) => {
      if (!platingOnAssembly.plating) return platingOnAssembly;

      const defaultGranitLink = `${buckets?.BUCKET_3D_PIECES || ''}/${
        platingOnAssembly.granit?.image
      }`;
      const loadedTexture = loader.load(defaultGranitLink);
      loadedTexture.wrapS = THREE.RepeatWrapping;
      loadedTexture.wrapT = THREE.MirroredRepeatWrapping;
      // loadedTexture.offset.x = 0.5;
      loadedTexture.repeat.x = 1;

      const shape = new Shape();
      const shapeDetails = {
        display: true,
        hasSlope: false,
        isRotated: platingOnAssembly.plating.isRotated,
        depth: platingOnAssembly.plating.depth,
        length: platingOnAssembly.plating.length,
        front: platingOnAssembly.plating.front,
        back: platingOnAssembly.plating.back ?? undefined,
        monumentWidth: platingOnAssembly.plating.monumentWidth,
        monumentLength: platingOnAssembly.plating.monumentLength,
        monumentFront: platingOnAssembly.plating.monumentFront ?? undefined,
        monumentBack: platingOnAssembly.plating.monumentBack ?? undefined,
      };

      changePlatingShape(
        shape,
        platingOnAssembly.plating.length,
        shapeDetails.front,
        shapeDetails.back,
      );

      return {
        id: generateUUID(),
        isActive: platingOnAssembly.plating.isActive,
        texture: loadedTexture,
        granit: platingOnAssembly.granit,
        isBlocked: platingOnAssembly.isBlocked,
        key: platingOnAssembly.plating.key,
        shape,
        shapeDetails,
        position: new Vector3(
          platingOnAssembly.position.x,
          platingOnAssembly.position.y,
          platingOnAssembly.position.z,
        ),
      };
    });

    if (formatedPlating) setCustomPlatings(formatedPlating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pieces, removeImportedPiece } = useImportPiece(
    activePieces,
    setActivePieces,
    history,
    setHistory,
  );
  const { data: granits, onRefresh: refreshGranits } = useLoadDataList(getGranits);

  const [activePiecesIds, setActivePiecesIds] = useState<number[]>([]);

  useEffect(() => {
    if (activePieces && activePieces.length > 0) {
      const ids = [...new Set(activePieces.map((element) => element.id))].sort((a, b) =>
        a > b ? 1 : -1,
      );

      if (ids?.toString() !== activePiecesIds?.toString()) {
        setActivePiecesIds(ids);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePieces]);

  useEffect(() => {
    const copyOfOldOriginalGeometry = originalGeometry ? [...originalGeometry] : [];

    const originalGeometryCopy = pieces.map((element, index) => {
      if (copyOfOldOriginalGeometry[index]) {
        return copyOfOldOriginalGeometry[index];
      }
      return new Float32Array(element.geometry.attributes.position.array);
    });

    setOriginalGeometry(originalGeometryCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieces]);

  const { data: constraintsOfPieces, onRefresh: refreshConstraints } = useLoadDataList(
    () => getConstraintsOfPieces([...new Set(activePieces.map((element) => element.id))]),
    [activePiecesIds],
  );

  const constraints = useMemo(() => {
    const constraintsOfPiece = constraintsOfPieces?.find(
      (element) => element.id === pieces[pieceIndex]?.id,
    );

    return constraintsOfPiece?.constraints;
  }, [pieceIndex, constraintsOfPieces, pieces]);

  const { data: modificationsOfPieces, onRefresh: refreshModifications } = useLoadDataList(
    () => getModificationsOfPieces([...new Set(activePieces.map((element) => element.id))]),
    [activePiecesIds],
  );

  const modifications = useMemo(() => {
    const modificationsOfPiece = modificationsOfPieces?.find(
      (element) => element.id === pieces[pieceIndex]?.id,
    );
    return modificationsOfPiece?.modifications;
  }, [pieceIndex, modificationsOfPieces, pieces]);

  const [reloadPiece, setReloadPiece] = useState<boolean>(false);
  const [selectedPoints, setSelectedPoints] = useState<
    { pieceIndex: number; point: Float32Array }[] | null
  >(null);

  const [userModifications, setUserModifications] = useState<Modification[] | undefined>(
    modifications,
  );

  useEffect(() => {
    if (modifications) {
      const updatedModifications = modifications.map((el) => {
        const elPoints = el.points.sort((a, b) => {
          if (a.index !== undefined && b.index !== undefined) {
            return a.index < b.index ? -1 : 1;
          }
          return 0;
        });

        if (!elPoints[0] || !elPoints[1]) return null;
        const newP1 = getModificationWithHistory(history[pieces[pieceIndex].uuid], elPoints[0]);
        const newP2 = getModificationWithHistory(history[pieces[pieceIndex].uuid], elPoints[1]);

        const vectorP1 = new Vector3(newP1.x, newP1.y, newP1.z);
        const vectorP2 = new Vector3(newP2.x, newP2.y, newP2.z);

        let distance = Number.parseFloat(vectorP1.distanceTo(vectorP2).toFixed(2));

        if (el.simpleModeAxis) {
          distance =
            vectorP2[el.simpleModeAxis] > vectorP1[el.simpleModeAxis]
              ? +(vectorP2[el.simpleModeAxis] - vectorP1[el.simpleModeAxis]).toFixed(2)
              : +(vectorP1[el.simpleModeAxis] - vectorP2[el.simpleModeAxis]).toFixed(2);
        }

        return {
          ...el,
          distance,
        };
      });

      setUserModifications(updatedModifications.filter((el) => el !== null) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifications, pieceIndex]);

  const removeOnePiece = (pieceIndexToRemove: number) => {
    if (pieceIndexToRemove > activePieces.length - 1) {
      setCustomSteles(
        customSteles.filter((el, index) => index !== pieceIndexToRemove - activePieces.length),
      );
    } else if (activePieces[pieceIndexToRemove]?.underAssembly) {
      const indexToDelete: number[] = [];
      for (const [elIndex, el] of activePieces.entries()) {
        if (
          el.underAssembly &&
          el.underAssembly.uuid === activePieces[pieceIndexToRemove]?.underAssembly.uuid
        ) {
          indexToDelete.push(elIndex);
        }
      }

      const newActivePiece = activePieces.filter((el, index) => !indexToDelete.includes(index));

      setActivePieces(newActivePiece);
      removeImportedPiece(indexToDelete);
      setPieceIndex(newActivePiece.length - 1);
    } else {
      const newActivePiece = activePieces.filter((el, index) => index !== pieceIndexToRemove);

      setActivePieces(newActivePiece);
      removeImportedPiece(pieceIndexToRemove);
      setPieceIndex(newActivePiece.length - 1);
    }
  };

  const applyConstraints = (
    constraints: Constraint[],
    axis: ('x' | 'y' | 'z')[],
    newHistory: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    },
    geometry: number[],
    copyGeometry: number[],
    newPoint: Float32Array,
    secondPoint: {
      x: number;
      y: number;
      z: number;
    },
    iteration?: number,
    constraintsAlreadyApply: Constraint[] = [],
    oldSecondPoint?: {
      x: number;
      y: number;
      z: number;
    },
  ) => {
    const oldPoint = pointToFloat32(secondPoint); // Point sans modification
    const currentSecondPoint = pointToFloat32(
      getModificationWithHistory(newHistory[pieces[pieceIndex].uuid], secondPoint),
    ); // Point à la position actiuel

    const constraintOfThisPoint = constraints.filter((c) => {
      // eslint-disable-next-line unicorn/prefer-array-some
      if (constraintsAlreadyApply && constraintsAlreadyApply.find((el) => el.id === c.id))
        return false;
      const floatPoints = c.points.map((p) => {
        return new Float32Array([p.x, p.y, p.z]);
      });

      // Je récupère l'index du point dans la liste de point de la contrainte
      const indexOfPointIntoConstraint = getIndexOfFloat32Array(floatPoints, oldPoint);

      // Je check si la contrainte est sur l'axe de modification du point
      if (indexOfPointIntoConstraint > -1) {
        const sameAxis = axis.filter((a) => !!c.axis.includes(a));
        return sameAxis.length > 0;
      }
      return false;
    });

    if (!constraintOfThisPoint || constraintOfThisPoint.length === 0) {
      return;
    }

    let currentPointsOfConstraint = [];
    const actualPointWhoWillBeChanged = [];

    for (const el of constraintOfThisPoint) {
      for (const element of el.points) {
        currentPointsOfConstraint.push({
          constraint: el,
          point: element,
          oldPoint: getModificationWithHistory(newHistory[pieces[pieceIndex].uuid], element),
        });
      }
    }

    for (const c of constraintOfThisPoint) {
      for (const constraintPoint of c.points) {
        if (!compare2Point(float32ToPoint(oldPoint), constraintPoint)) {
          const oldConstraintPoint = pointToFloat32(constraintPoint);
          const newConstraintPoint = pointToFloat32(
            getModificationWithHistory(newHistory[pieces[pieceIndex].uuid], constraintPoint),
          );
          actualPointWhoWillBeChanged.push(constraintPoint);

          if (c.axis.includes('x') && c.axis.includes('y') && c.axis.includes('z')) {
            const secondPointTmp = oldSecondPoint
              ? pointToFloat32(oldSecondPoint)
              : currentSecondPoint;

            let axisOfPointsStuck: any = null;
            if (c.points && c.points.length >= 2) {
              if (c.points[0].x === c.points[1].x) axisOfPointsStuck = 'x';
              if (c.points[0].y === c.points[1].y) axisOfPointsStuck = 'y';
              if (c.points[0].z === c.points[1].z) axisOfPointsStuck = 'z';

              if (!axis.includes(axisOfPointsStuck)) {
                newConstraintPoint[0] += newPoint[0] - secondPointTmp[0];
                newConstraintPoint[1] += newPoint[1] - secondPointTmp[1];
                newConstraintPoint[2] += newPoint[2] - secondPointTmp[2];
              }
            }
          } else {
            for (const a of c.axis) {
              if (a === 'x') newConstraintPoint[0] = newPoint[0];
              if (a === 'y') newConstraintPoint[1] = newPoint[1];
              if (a === 'z') newConstraintPoint[2] = newPoint[2];
            }
          }
          const currentPointHistory = getModificationWithHistory(
            history[pieces[pieceIndex].uuid],
            float32ToPoint(oldConstraintPoint),
          );

          updtateGeometryWithCoord(
            geometry,
            copyGeometry,
            pointToFloat32(currentPointHistory),
            newConstraintPoint,
          );

          newHistory[pieces[pieceIndex].uuid][getPointHistoryName(oldConstraintPoint)] =
            newConstraintPoint;
        }
      }
    }

    currentPointsOfConstraint = currentPointsOfConstraint.map((el) => {
      return {
        ...el,
        newPoint: getModificationWithHistory(newHistory[pieces[pieceIndex].uuid], el.point),
      };
    });

    for (const [i, el] of currentPointsOfConstraint.entries()) {
      applyConstraints(
        constraints,
        axis,
        newHistory,
        geometry,
        copyGeometry,
        pointToFloat32(currentPointsOfConstraint[i].newPoint),
        currentPointsOfConstraint[i].point,
        iteration ? iteration + 1 : 1,
        constraintsAlreadyApply
          ? [...constraintsAlreadyApply, ...constraintOfThisPoint]
          : [...constraintOfThisPoint],
        currentPointsOfConstraint[i].oldPoint,
      );

      if (!constraintsAlreadyApply.includes(el.constraint))
        constraintsAlreadyApply.push(el.constraint);
    }
  };

  const updatePointsAndConstraints = (
    newHistory: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    },
    geometry: number[],
    copyGeometry: number[],
    firstPoint: {
      x: number;
      y: number;
      z: number;
    },
    secondPoint: {
      x: number;
      y: number;
      z: number;
    },
    axis: ('x' | 'z' | 'y')[],
    delta: number,
  ) => {
    const oldPoint = pointToFloat32(secondPoint);
    const currentFirstPoint = pointToFloat32(
      getModificationWithHistory(newHistory[pieces[pieceIndex].uuid], firstPoint),
    );

    const oldV1 = new Vector3(firstPoint.x, firstPoint.y, firstPoint.z);
    const oldV2 = new Vector3(secondPoint.x, secondPoint.y, secondPoint.z);

    const oldV3 = oldV1.clone().negate().add(oldV2);
    const oldDistance = oldV1.distanceTo(oldV2).toFixed(2);

    const v1 = new Vector3(currentFirstPoint[0], currentFirstPoint[1], currentFirstPoint[2]);

    const newV2 = v1.clone().addScaledVector(oldV3, delta / Number.parseFloat(oldDistance));

    const newPoint = pointToFloat32(newV2);

    if (constraints) {
      applyConstraints(
        constraints,
        axis,
        newHistory,
        geometry,
        copyGeometry,
        newPoint,
        secondPoint,
      );
    }

    const currentPointHistory = getModificationWithHistory(
      history[pieces[pieceIndex].uuid],
      float32ToPoint(oldPoint),
    );

    updtateGeometryWithCoord(geometry, copyGeometry, pointToFloat32(currentPointHistory), newPoint);
    newHistory[pieces[pieceIndex].uuid][getPointHistoryName(oldPoint)] = newPoint;
  };

  const changePoint = (
    newCoords: {
      firstPoint: { x: number; y: number; z: number };
      secondPoint: { x: number; y: number; z: number };
      axis: ('x' | 'z' | 'y')[];
      delta: number;
    }[],
  ) => {
    const newHistory = Object.fromEntries(
      Object.entries(history).map(([k, v]) => [
        k,
        Object.fromEntries(
          Object.entries(v).map(([innerKey, innerValue]) => [
            innerKey,
            new Float32Array(innerValue),
          ]),
        ),
      ]),
    );

    let newUserModification: Modification[] | undefined = userModifications
      ? [...userModifications]
      : undefined;
    const geometry: number[] = pieces[pieceIndex].geometry.attributes.position.array;
    const copyGeometry: number[] = Array.from({ length: geometry.length }).fill(0) as number[];

    const box = new Box3().setFromArray(pieces[pieceIndex].geometry.attributes.position.array);
    const boundingBoxSize = box.max.sub(box.min);

    for (const { firstPoint, secondPoint, axis, delta } of newCoords) {
      // update the right line
      updatePointsAndConstraints(
        newHistory,
        geometry,
        copyGeometry,
        firstPoint,
        secondPoint,
        axis,
        delta,
      );

      newUserModification = newUserModification?.map((modif) => {
        const newP1 = getModificationWithHistory(
          newHistory[pieces[pieceIndex].uuid],
          modif.points[0],
        );
        const newP2 = getModificationWithHistory(
          newHistory[pieces[pieceIndex].uuid],
          modif.points[1],
        );

        const newV1 = new Vector3(newP1.x, newP1.y, newP1.z);
        const newV2 = new Vector3(newP2.x, newP2.y, newP2.z);

        const d = newV1.distanceTo(newV2);

        if (
          (compare2Point(modif.points[0], firstPoint) ||
            compare2Point(modif.points[0], secondPoint)) &&
          (compare2Point(modif.points[1], firstPoint) ||
            compare2Point(modif.points[1], secondPoint))
        ) {
          return {
            ...modif,
            distance: d,
          };
        }
        return modif;
      });

      // Check if all modifications data are rights
      if (newUserModification) {
        let numberOfIteration = 0;
        for (let i = 0; i < newUserModification?.length; i += 1) {
          const modif = newUserModification[i];
          if (modif.pieceIndex === pieceIndex) {
            const newP1 = getModificationWithHistory(
              newHistory[pieces[pieceIndex].uuid],
              modif.points[0],
            );
            const newP2 = getModificationWithHistory(
              newHistory[pieces[pieceIndex].uuid],
              modif.points[1],
            );

            const newV1 = new Vector3(newP1.x, newP1.y, newP1.z);
            const newV2 = new Vector3(newP2.x, newP2.y, newP2.z);

            const deltaOldV1 = modif.points[0].x + modif.points[0].y + modif.points[0].z;
            const deltaOldV2 = modif.points[1].x + modif.points[1].y + modif.points[1].z;

            const deltaNewV1 = newP1.x + newP1.y + newP1.z;
            const deltaNewV2 = newP2.x + newP2.y + newP2.z;

            const isInvert =
              (deltaOldV1 < deltaOldV2 && deltaNewV1 > deltaNewV2) ||
              (deltaOldV1 > deltaOldV2 && deltaNewV1 < deltaNewV2);

            // Caution, it's vector distance so v1 to v2 === v2 to v1
            const d = newV1.distanceTo(newV2);

            // Check all points who are not the point that we've just changed
            if (
              (!compare2Point(modif.points[0], firstPoint) ||
                !compare2Point(modif.points[0], secondPoint)) &&
              (!compare2Point(modif.points[1], firstPoint) ||
                !compare2Point(modif.points[1], secondPoint)) && // If actual distance is not equal to saved distance, then we update
              (d.toPrecision(4) !== modif.distance.toPrecision(4) || isInvert)
            ) {
              const v2supv1 = isPointBigger(modif.points[0], modif.points[1]);

              updatePointsAndConstraints(
                newHistory,
                geometry,
                copyGeometry,
                modif.points[v2supv1 ? 0 : 1],
                modif.points[v2supv1 ? 1 : 0],
                getMultiMovingAxisOf2Point(modif.points[0], modif.points[1]),
                modif.distance,
              );

              if (numberOfIteration < 100) {
                numberOfIteration += 1;

                i = -1;
              }
            }
          }
        }
      }
    }

    pieces[pieceIndex].geometry.attributes.position.array = geometry.map((el, index) => {
      if (copyGeometry[index] !== 0) {
        return copyGeometry[index];
      }
      return el;
    });

    const position = pieces[pieceIndex].geometry.getAttribute('position');
    position.needsUpdate = true;

    setUserModifications(newUserModification);

    const newBox = new Box3().setFromArray(pieces[pieceIndex].geometry.attributes.position.array);

    centerGeometry(boundingBoxSize as any, newBox, newHistory);

    if (box.min.x !== newBox.min.x || box.max.x !== newBox.max.x) checkCollision(pieceIndex, 'x');
    if (box.min.y !== newBox.min.y || box.max.y !== newBox.max.y) checkCollision(pieceIndex, 'y');
    if (box.min.z !== newBox.min.z || box.max.z !== newBox.max.z) checkCollision(pieceIndex, 'z');
  };

  const centerGeometry = (
    oldBox: Box3 & { x: number },
    newBox: Box3,
    newHistory: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    },
  ) => {
    const newBoundingBoxSize = newBox.max.sub(newBox.min);

    const deltaX = newBoundingBoxSize.x - oldBox.x;

    const pieceGeometry = pieces[pieceIndex].geometry.attributes.position.array;

    const copyGeometry: number[] = Array.from({ length: pieceGeometry.length }).fill(0) as number[];
    for (let i = 0; i < pieceGeometry.length; i += 3) {
      copyGeometry[i] = pieceGeometry[i] - deltaX / 2;
      copyGeometry[i + 1] = pieceGeometry[i + 1];
      copyGeometry[i + 2] = pieceGeometry[i + 2];
    }

    changeGeometry(pieceIndex, copyGeometry, newHistory);
  };

  const centerCamera = useCallback(
    (azimuthAngleDeg = 30, polarAngleDeg?: number) => {
      const array: number[] = [];

      for (const el of pieces) {
        const box1Geometry: number[] = el.geometry.attributes.position.array;
        const box1Postion = el.position;

        for (const [elementIndex, element] of box1Geometry.entries()) {
          if (elementIndex % 3 === 0) array.push(element + box1Postion.x);
          if (elementIndex % 3 === 1) array.push(element + box1Postion.y);
          if (elementIndex % 3 === 2) array.push(element + box1Postion.z);
        }
      }

      for (const el of customPlatings) {
        if (el.shape) {
          const box1GeometryThree = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const box1Geometry: number[] = box1GeometryThree.attributes.position.array;
          const box1Postion = el.position;

          for (const [elementIndex, element] of box1Geometry.entries()) {
            if (elementIndex % 3 === 0) array.push((element + box1Postion.x) / 1.5);
            if (elementIndex % 3 === 1) array.push(element + box1Postion.y);
            if (elementIndex % 3 === 2) array.push((element + box1Postion.z) / 1.5);
          }
        }
      }

      for (const el of customSteles) {
        if (el.shape) {
          const box1GeometryThree = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const box1Geometry: number[] = box1GeometryThree.attributes.position.array;
          const box1Postion = el.position;

          for (const [elementIndex, element] of box1Geometry.entries()) {
            if (elementIndex % 3 === 0) array.push(element + box1Postion.x);
            if (elementIndex % 3 === 1) array.push(element + box1Postion.y);
            if (elementIndex % 3 === 2) array.push(element + box1Postion.z);
          }
        }
      }

      const piecesBox = new THREE.Box3();
      piecesBox.setFromArray(array);

      const center = new Vector3();
      const size = new Vector3();
      piecesBox.getCenter(center);
      piecesBox.getSize(size);

      const { width, height } = gl?.domElement.getBoundingClientRect() || { width: 1, height: 1 };
      const aspectRatio = width / height;

      const fov = 40; // en degrés
      const fovRad = (fov * Math.PI) / 180;
      const maxDimensionX = size.x;
      const maxDimensionY = size.y / aspectRatio;
      const maxDimensionZ = size.z;
      const numberOfPieces = pieces.length + customPlatings.length + customSteles.length;
      const maxDimension =
        Math.max(maxDimensionX, maxDimensionY, maxDimensionZ) * (numberOfPieces > 1 ? 0.8 : 1);
      const distance = maxDimension / Math.tan(fovRad / 2);

      const azimuthAngleRad = azimuthAngleDeg
        ? (azimuthAngleDeg * Math.PI) / 180
        : 0.404_891_786_285_083_4;
      const polarAngleRad = polarAngleDeg
        ? (polarAngleDeg * Math.PI) / 180
        : 1.313_982_409_374_089_5;

      if (orbitRef.current) {
        orbitRef.current.enableRotate = true;
        orbitRef.current.object.fov = fov;

        const theta = azimuthAngleRad; // angle horizontal
        const phi = polarAngleRad; // angle vertical

        const position = new Vector3(
          center.x + distance * Math.sin(phi) * Math.cos(theta),
          center.y + distance * Math.cos(phi),
          center.z + distance * Math.sin(phi) * Math.sin(theta),
        );

        orbitRef.current.position0.copy(position);
        orbitRef.current.reset();
        orbitRef.current.target = center;
        orbitRef.current.setAzimuthalAngle(azimuthAngleRad);
        orbitRef.current.setPolarAngle(polarAngleRad);
        orbitRef.current.object.zoom = 0.9;
        orbitRef.current.object.updateProjectionMatrix();
      }
    },
    [gl?.domElement, pieces, customPlatings, customSteles, orbitRef],
  );

  const checkCollision = (pieceIndexToCheck: number, axis: 'x' | 'y' | 'z') => {
    const deltaPosBox1 = {
      x: 0,
      y: 0,
      z: 0,
    };
    const deltaPosBox2 = {
      x: 0,
      y: 0,
      z: 0,
    };

    for (let i = 0; i < pieces.length; i += 1) {
      if (pieceIndexToCheck !== i && !activePieces[i].isBlocked) {
        deltaPosBox1.x = 0;
        deltaPosBox1.y = 0;
        deltaPosBox1.z = 0;

        deltaPosBox2.x = 0;
        deltaPosBox2.y = 0;
        deltaPosBox2.z = 0;

        const box1Geometry = pieces[pieceIndexToCheck].geometry.attributes.position.array;
        const box1Postion = pieces[pieceIndexToCheck].position;

        const box1 = new Box3().setFromArray(
          // eslint-disable-next-line array-callback-return
          box1Geometry.map((el: any, index: number) => {
            if (index % 3 === 0) return el + box1Postion.x;
            if (index % 3 === 1) return el + box1Postion.y;
            if (index % 3 === 2) return el + box1Postion.z;
          }),
        );
        const box2 = new Box3().setFromArray(
          // eslint-disable-next-line array-callback-return
          pieces[i].geometry.attributes.position.array.map((el: any, index: number) => {
            if (index % 3 === 0) return el + pieces[i].position.x;
            if (index % 3 === 1) return el + pieces[i].position.y;
            if (index % 3 === 2) return el + pieces[i].position.z;
          }),
        );

        const box1Copy = box1.clone();
        if (box1Copy.intersectsBox(box2)) {
          if (
            activePieces[i].underAssembly &&
            (!activePieces[pieceIndexToCheck].underAssembly ||
              activePieces[pieceIndexToCheck].underAssembly.id !== activePieces[i].underAssembly.id)
          ) {
            // pour ne pas bouger les pièces qui sont dans le même sous-assemblage
            return;
          }
          const isBox1AfterBox2OnX =
            box1.getCenter(new Vector3()).x >= box2.getCenter(new Vector3()).x;
          const isBox1AfterBox2OnY = box1.min.y >= box2.min.y;
          const isBox1AfterBox2OnZ =
            box1.getCenter(new Vector3()).z >= box2.getCenter(new Vector3()).z;

          if (
            axis === 'x' &&
            box1.max.z !== box2.min.z &&
            box1.min.z !== box2.max.z &&
            box1.max.y !== box2.min.y &&
            box1.min.y !== box2.max.y
          ) {
            const delta = box1.min.x - box2.max.x;
            if (delta >= 1 && delta <= 1) return;
            if (isBox1AfterBox2OnX && box1.min.x < box2.max.x && deltaPosBox1.x > delta) {
              if (box1.min.x < 0) {
                deltaPosBox2.x = delta * -1;
              } else {
                deltaPosBox1.x = delta;
              }
            }
            if (
              !isBox1AfterBox2OnX &&
              box1.max.x > box2.min.x &&
              deltaPosBox1.x < box1.max.x - box2.min.x
            ) {
              if (box1.max.x > 0) {
                deltaPosBox2.x = (box1.max.x - box2.min.x) * -1;
              } else {
                deltaPosBox1.x = box1.max.x - box2.min.x;
              }
            }
          } else if (
            axis === 'z' &&
            box1.max.x !== box2.min.x &&
            box1.min.x !== box2.max.x &&
            box1.max.y !== box2.min.y &&
            box1.min.y !== box2.max.y
          ) {
            if (!isBox1AfterBox2OnZ && box1.max.z > box2.min.z) {
              deltaPosBox2.z = (box1.max.z - box2.min.z) * -1;
            }
          } else if (
            axis === 'y' &&
            box1.max.x !== box2.min.x &&
            box1.min.x !== box2.max.x &&
            box1.max.z !== box2.min.z &&
            box1.min.z !== box2.max.z &&
            box1.max.y > box2.min.y &&
            !isBox1AfterBox2OnY
          ) {
            deltaPosBox2.y = (box1.max.y - box2.min.y) * -1;
          }

          movePieceWithPieceDeltaAndAxis(pieces[pieceIndexToCheck], deltaPosBox1);
          movePieceWithPieceDeltaAndAxis(pieces[i], deltaPosBox2);
          setReloadPiece(true);
        }
      }
    }
    centerCamera();
  };

  const changeGeometry = (
    indexToChange: number,
    newGeometry: number[],
    newHistory?: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    },
  ) => {
    const historyToUse: {
      [k: string]: {
        [k: string]: Float32Array;
      };
    } = newHistory || { ...history };

    const geometry: number[] = pieces[indexToChange].geometry.attributes.position.array;

    let pointsToUpdate = [];

    for (let i = 0; i < geometry.length; i += 3) {
      const point = new Float32Array([geometry[i], geometry[i + 1], geometry[i + 2]]);
      const newPoint = new Float32Array([newGeometry[i], newGeometry[i + 1], newGeometry[i + 2]]);

      pieces[indexToChange].geometry.attributes.position.array[i] = newPoint[0];
      pieces[indexToChange].geometry.attributes.position.array[i + 1] = newPoint[1];
      pieces[indexToChange].geometry.attributes.position.array[i + 2] = newPoint[2];
      pointsToUpdate.push({ old: point, new: newPoint });
    }

    // update bouding box and bounding sphere
    updateBoundingBox(pieces[indexToChange]);

    pointsToUpdate = pointsToUpdate.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => compare2Float32Array(t.old, value.old) && compare2Float32Array(t.new, value.new),
        ),
    );

    const asyncChangeHistoryArray: any[] = [];

    for (const p of pointsToUpdate) {
      asyncChangeHistoryArray.push({
        name: getReverseHistoryName(historyToUse[pieces[indexToChange].uuid], p.old),
        point: p.new,
      });
    }

    for (const element of asyncChangeHistoryArray) {
      historyToUse[pieces[indexToChange].uuid][element.name] = element.point;
    }

    const position = pieces[indexToChange].geometry.getAttribute('position');
    position.needsUpdate = true;

    let newUserModification: Modification[] | undefined = userModifications
      ? [...userModifications]
      : undefined;

    newUserModification = newUserModification?.map((modif) => {
      const elPoints = modif.points.sort((a, b) => {
        if (a.index !== undefined && b.index !== undefined) {
          return a.index < b.index ? -1 : 1;
        }
        return 0;
      });

      const newP1 = getModificationWithHistory(historyToUse[pieces[pieceIndex].uuid], elPoints[0]);
      const newP2 = getModificationWithHistory(historyToUse[pieces[pieceIndex].uuid], elPoints[1]);

      const newV1 = new Vector3(newP1.x, newP1.y, newP1.z);
      const newV2 = new Vector3(newP2.x, newP2.y, newP2.z);

      let d = newV1.distanceTo(newV2);

      if (modif.simpleModeAxis) {
        d =
          newV2[modif.simpleModeAxis] > newV1[modif.simpleModeAxis]
            ? newV2[modif.simpleModeAxis] - newV1[modif.simpleModeAxis]
            : newV1[modif.simpleModeAxis] - newV2[modif.simpleModeAxis];
      }

      if (d !== modif.distance) {
        return {
          ...modif,
          distance: d,
        };
      }
      return modif;
    });

    if (pieces[indexToChange].geometry.attributes.uv) {
      pieces[indexToChange].geometry.attributes.uv.array =
        pieces[indexToChange].geometry.attributes.position.array;
      pieces[indexToChange].geometry.attributes.uv.needsUpdate = true;
    }

    setUserModifications(newUserModification);

    setHistory(historyToUse);
    setReloadPiece(true);
  };

  const changePosition = (newPosition: Vector3, pieceIndexToChange?: number) => {
    pieces[pieceIndexToChange === undefined ? pieceIndex : pieceIndexToChange]?.position.set(
      newPosition.x,
      newPosition.y,
      newPosition.z,
    );
  };

  const movePieceOnAxe = (axe: 'x' | 'y' | 'z') => {
    const axeDict = { x: 0, y: 1, z: 2 };

    if (selectedPoints?.length === 2) {
      const firstPointPosition = {
        x: selectedPoints[0].point[axeDict.x],
        y: selectedPoints[0].point[axeDict.y],
        z: selectedPoints[0].point[axeDict.z],
      };

      const secondPointPosition = {
        x: selectedPoints[1].point[axeDict.x],
        y: selectedPoints[1].point[axeDict.y],
        z: selectedPoints[1].point[axeDict.z],
      };

      const firstPiecePosition = {
        x: pieces[selectedPoints[0].pieceIndex]?.position.x,
        y: pieces[selectedPoints[0].pieceIndex]?.position.y,
        z: pieces[selectedPoints[0].pieceIndex]?.position.z,
      };

      const secondPiecePosition = {
        x: pieces[selectedPoints[1].pieceIndex]?.position.x,
        y: pieces[selectedPoints[1].pieceIndex]?.position.y,
        z: pieces[selectedPoints[1].pieceIndex]?.position.z,
      };

      firstPointPosition[axe] = firstPointPosition[axe] + firstPiecePosition[axe];

      secondPointPosition[axe] = secondPointPosition[axe] + secondPiecePosition[axe];

      const distance = secondPointPosition[axe] - firstPointPosition[axe];

      firstPiecePosition[axe] = firstPiecePosition[axe] + distance;

      pieces[selectedPoints[0].pieceIndex]?.position.set(
        firstPiecePosition.x,
        firstPiecePosition.y,
        firstPiecePosition.z,
      );

      pieces[selectedPoints[0].pieceIndex].needsUpdate = true;
      setReloadPiece(true);
    }
  };

  const findPointOnTheSameAxe = (axe: 'x' | 'z' | 'y') => {
    if (selectedPoints?.length === 1) {
      const selectedPiece: any = pieces[pieceIndex];

      const newSelectedPoints = [...selectedPoints];
      for (let i = 0; i < selectedPiece.geometry.attributes.position.array.length; i += 3) {
        const pos = {
          x: selectedPiece.geometry.attributes.position.array[i],
          y: selectedPiece.geometry.attributes.position.array[i + 1],
          z: selectedPiece.geometry.attributes.position.array[i + 2],
        };

        if (axe === 'x' && pos.x === selectedPoints[0].point[0]) {
          newSelectedPoints.push({
            point: new Float32Array([pos.x, pos.y, pos.z]),
            pieceIndex,
          });
        }
        if (axe === 'y' && pos.y === selectedPoints[0].point[1]) {
          newSelectedPoints.push({
            point: new Float32Array([pos.x, pos.y, pos.z]),
            pieceIndex,
          });
        }
        if (axe === 'z' && pos.z === selectedPoints[0].point[2]) {
          newSelectedPoints.push({
            point: new Float32Array([pos.x, pos.y, pos.z]),
            pieceIndex,
          });
        }
      }
      setSelectedPoints(newSelectedPoints);
    }
  };

  const isBetween = (num: number, a: number, b: number) => {
    const min = Math.min.apply(Math, [a, b]);
    const max = Math.max.apply(Math, [a, b]);
    return num >= min && num <= max;
  };

  const findPointAround = () => {
    if (selectedPoints?.length === 2) {
      const selectedPiece: any = pieces[pieceIndex];

      const newSelectedPoints = [...selectedPoints];
      for (let i = 0; i < selectedPiece.geometry.attributes.position.array.length; i += 3) {
        const pos = {
          x: selectedPiece.geometry.attributes.position.array[i],
          z: selectedPiece.geometry.attributes.position.array[i + 1],
          y: selectedPiece.geometry.attributes.position.array[i + 2],
        };

        if (
          isBetween(pos.x, selectedPoints[0].point[0], selectedPoints[1].point[0]) &&
          isBetween(pos.z, selectedPoints[0].point[1], selectedPoints[1].point[1]) &&
          isBetween(pos.y, selectedPoints[0].point[2], selectedPoints[1].point[2])
        ) {
          newSelectedPoints.push({
            point: new Float32Array([pos.x, pos.z, pos.y]),
            pieceIndex,
          });
        }
      }
      setSelectedPoints(newSelectedPoints);
    }
  };

  type Details = {
    faceX: number;
    faceX2: number;
    faceY: number;
    faceY2: number;
    faceZ: number;
    faceZ2: number;
  };

  const getPiecePrice = (
    activePiece: {
      polishingX: number;
      polishingY: number;
      polishingZ: number;
      sawingX: number;
      sawingY: number;
      sawingZ: number;
      importExtra: number;
      frenchExtra: number;
      granit: Granit;
      frenchLinearDetails: Details;
      frenchM2Details: Details;
      importLinearDetails: Details;
      importM2Details: Details;
      name?: string;
      type: string;
    },
    x: number,
    y: number,
    z: number,
    elIndex?: number,
  ) => {
    const assemblyUser = assembly?.quote?.user;

    // (((M3 monument * Prix au M3) + (Polissage * M2) + (sciage * M2)) * Coeff Admin France + plus values) * COEFF GRANIT CLIENT + (Transport à la Tonne * M3 * densité granit) * COEFF TRANSPORT CLIENT
    // + Tarif en euros accessoires * COEFF ACCESSOIRES CLIENT + (Transport à la Tonne * M3 * densité granit) * COEFF TRANSPORT CLIENT
    // + Nombre de lettres gravées * Tarif TTC à la lettre
    // + Tarif en euros Motif * Coeff MOTIF)
    // * Remise ou majoration (en % ou en euros sur le TTC) / si majoration, ventiler sur les différentes lignes

    const granitM3Price = activePiece.granit?.volumePrice ?? 0;
    const granitPurchaseM3Price = activePiece.granit?.purchaseVolumePrice ?? 0;
    const granitDensity = activePiece.granit?.weight / 1000;
    const granitPolishingPrice = activePiece.granit?.polishingPrice ?? 0;
    const granitPurchasePolishingPrice = activePiece.granit?.purchasePolishingPrice ?? 0;
    const granitSawingPrice = activePiece.granit?.sawingPrice ?? 0;
    const granitPurchaseSawingPrice = activePiece.granit?.purchaseSawingPrice ?? 0;
    const adminCoeffImport = assemblyUser?.userCoefficients?.coeffImport ?? 1;
    const adminCoeffFr = assemblyUser?.userCoefficients?.coeffFr ?? 1;
    const adminCoeffEcoImport = assemblyUser?.userCoefficients?.coeffEcoImport ?? 1;
    const adminCoeffEcoFr = assemblyUser?.userCoefficients?.coeffEcoFr ?? 1;
    let deliveryPrice = Number.parseFloat(assemblyUser?.deliveryCost?.price ?? '0') ?? 0;
    const userCoeffDelivery = assemblyUser?.userCoefficients?.delivery ?? 1;
    const size = x * y * z;
    const TVA = 1.2;

    let ttc = 0;
    let ht = 0;

    if (assemblyUser?.quoteSettings?.postageDue) deliveryPrice = 0;
    let m2ToPolish = 0;
    if (activePiece.polishingX) m2ToPolish += z * y * activePiece.polishingX;
    if (activePiece.polishingY) m2ToPolish += x * z * activePiece.polishingY;
    if (activePiece.polishingZ) m2ToPolish += x * y * activePiece.polishingZ;

    let m2ToSaw = 0;
    if (activePiece.sawingX) m2ToSaw += z * y * activePiece.sawingX;
    if (activePiece.sawingY) m2ToSaw += x * z * activePiece.sawingY;
    if (activePiece.sawingZ) m2ToSaw += x * y * activePiece.sawingZ;

    let granitCoeff = 2.3;
    let monumentType: 'MONUMENT' | 'SOLE/PLATING' | 'COLUMBARIUM' | 'CINERARY';

    if (activePiece.type === 'SOLE' || activePiece.type === 'PLATING') {
      monumentType = 'SOLE/PLATING';
    } else {
      monumentType = assembly?.type ?? 'MONUMENT';
    }
    if (assemblyUser?.userCoefficients?.isDetailed) {
      if (
        activePiece.granit?.fabrication === 'FRENCH' &&
        assemblyUser?.detailedCoefs[monumentType] &&
        assemblyUser?.detailedCoefs[monumentType][activePiece.granit.id]
      ) {
        granitCoeff = assemblyUser?.detailedCoefs[monumentType][activePiece.granit.id];
      } else if (
        activePiece.granit?.fabrication !== 'FRENCH' &&
        assemblyUser?.detailedCoefs[monumentType] &&
        assemblyUser?.detailedCoefs[monumentType][activePiece.granit.id]
      ) {
        granitCoeff = assemblyUser?.detailedCoefs[monumentType][activePiece.granit.id];
      }
    } else if (activePiece.granit?.fabrication === 'FRENCH') {
      const coeff = activePiece.granit.eco
        ? assemblyUser?.userCoefficients?.frenchEco ?? 2.3
        : assemblyUser?.userCoefficients?.frenchNormal ?? 2.3;
      granitCoeff = coeff ?? 2.3;
    } else {
      const coeff = activePiece.granit.eco
        ? assemblyUser?.userCoefficients?.importEco ?? 2.3
        : assemblyUser?.userCoefficients?.importNormal ?? 2.3;
      granitCoeff = coeff ?? 2.3;
    }

    let extra = 0;

    let largeExtraCoeff = 1;
    const getValue = (value: number | undefined) => {
      if (typeof value === 'number') return value;
      return 0;
    };

    if (
      x * 100 > Number(activePiece.granit.largeSize) ||
      y * 100 > Number(activePiece.granit.largeSize) ||
      z * 100 > Number(activePiece.granit.largeSize)
    ) {
      largeExtraCoeff = Number(activePiece.granit.largeExtra);
    }

    if (activePiece.granit?.fabrication === 'FRENCH') {
      extra += activePiece.frenchExtra;

      extra += getValue(activePiece?.frenchLinearDetails?.faceX) * x;
      extra += getValue(activePiece?.frenchLinearDetails?.faceX2) * x;
      extra += getValue(activePiece?.frenchLinearDetails?.faceY) * y;
      extra += getValue(activePiece?.frenchLinearDetails?.faceY2) * y;
      extra += getValue(activePiece?.frenchLinearDetails?.faceZ) * z;
      extra += getValue(activePiece?.frenchLinearDetails?.faceZ2) * z;

      extra += getValue(activePiece?.frenchM2Details?.faceX) * (z * y);
      extra += getValue(activePiece?.frenchM2Details?.faceX2) * (z * y);
      extra += getValue(activePiece?.frenchM2Details?.faceY) * (z * x);
      extra += getValue(activePiece?.frenchM2Details?.faceY2) * (z * x);
      extra += getValue(activePiece?.frenchM2Details?.faceZ) * (x * y);
      extra += getValue(activePiece?.frenchM2Details?.faceZ2) * (x * y);
    } else {
      extra += activePiece.importExtra;

      extra += getValue(activePiece?.importLinearDetails?.faceX) * x;
      extra += getValue(activePiece?.importLinearDetails?.faceX2) * x;
      extra += getValue(activePiece?.importLinearDetails?.faceY) * y;
      extra += getValue(activePiece?.importLinearDetails?.faceY2) * y;
      extra += getValue(activePiece?.importLinearDetails?.faceZ) * z;
      extra += getValue(activePiece?.importLinearDetails?.faceZ2) * z;

      extra += getValue(activePiece?.importM2Details?.faceX) * (z * y);
      extra += getValue(activePiece?.importM2Details?.faceX2) * (z * y);
      extra += getValue(activePiece?.importM2Details?.faceY) * (z * x);
      extra += getValue(activePiece?.importM2Details?.faceY2) * (z * x);
      extra += getValue(activePiece?.importM2Details?.faceZ) * (x * y);
      extra += getValue(activePiece?.importM2Details?.faceZ2) * (x * y);
    }

    // pour le bon de commande on enleve toutes les plus vaklues, tous le transport, tous les coeff,
    // donc il faut pour chaque granit: prix du m2 à polire en achat, à scier en achet et m3 achat
    // et ce sera un prux HT bon acaht

    const purchasePrice =
      size * granitPurchaseM3Price +
      m2ToPolish * granitPurchasePolishingPrice +
      m2ToSaw * granitPurchaseSawingPrice;

    // eslint-disable-next-line unicorn/prefer-ternary
    if (activePiece.granit?.fabrication === 'FRENCH') {
      const adminCoeff = activePiece.granit.eco ? adminCoeffEcoFr : adminCoeffFr;

      const basePrice = Math.ceil(
        (size * (granitM3Price * largeExtraCoeff) +
          m2ToPolish * granitPolishingPrice +
          m2ToSaw * granitSawingPrice) *
          adminCoeff +
          extra,
      );

      ht = basePrice;
      ttc = basePrice * granitCoeff * TVA;
    } else {
      const adminCoeff = activePiece.granit.eco ? adminCoeffEcoImport : adminCoeffImport;
      const basePrice = Math.ceil(size * (granitM3Price * largeExtraCoeff) * adminCoeff);

      ht = basePrice + extra;
      ttc = (basePrice + extra) * granitCoeff * TVA;
    }

    // if (
    //   activePiece.type === 'SOLE' &&
    //   assemblyUser?.quoteSettings?.activeSoleInstallation &&
    //   assemblyUser?.quoteSettings?.addInstallationToSolePrice
    // ) {
    //   const numberOfSole = activePieces.filter((el) => el.type === 'SOLE').length;

    //   const soleInstallation = Number.parseFloat(
    //     String(assemblyUser?.quoteSettings?.soleInstallation ?? 0),
    //   );
    //   const installationPerSole = new Decimal(soleInstallation)
    //     .dividedBy(numberOfSole)
    //     .toDecimalPlaces(2);
    //   const delta = new Decimal(soleInstallation).minus(installationPerSole.times(numberOfSole));

    //   if (
    //     elIndex ===
    //     activePieces.findIndex(
    //       (el, i, arr) =>
    //         el.type === 'SOLE' && i === arr.lastIndexOf(arr.find((p) => p.type === 'SOLE')),
    //     )
    //   ) {
    //     ttc += installationPerSole.plus(delta).toNumber();
    //   } else {
    //     ttc += installationPerSole.toNumber();
    //   }
    // }

    // if (
    //   activePiece.type !== 'SOLE' &&
    //   assemblyUser?.quoteSettings?.activeMonumentInstallation &&
    //   assemblyUser?.quoteSettings?.addInstallationToMonumentPrice
    // ) {
    //   const numberOfMonument = activePieces.filter((el) => el.type !== 'SOLE').length;

    //   const monumentInstallation = Number.parseFloat(
    //     String(assemblyUser?.quoteSettings?.monumentInstallation ?? 0),
    //   );
    //   const installationPerMonument = new Decimal(monumentInstallation)
    //     .dividedBy(numberOfMonument)
    //     .toDecimalPlaces(2);
    //   const delta = new Decimal(monumentInstallation).minus(
    //     installationPerMonument.times(numberOfMonument),
    //   );

    //   if (
    //     elIndex ===
    //     activePieces.findIndex(
    //       (el, i, arr) =>
    //         el.type !== 'SOLE' && i === arr.lastIndexOf(arr.find((p) => p.type !== 'SOLE')),
    //     )
    //   ) {
    //     ttc += installationPerMonument.plus(delta).toNumber();
    //   } else {
    //     ttc += installationPerMonument.toNumber();
    //   }
    // }

    return {
      ttc: Number.parseFloat(ttc.toFixed(2)),
      ht: Number.parseFloat(ht.toFixed(2)),
      purchase: purchasePrice,
    };
  };

  const getAssemblyDetails = () => {
    const assemblyUser = assembly?.quote?.user;
    const piecesDetails = pieces.map((el, elIndex) => {
      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        el.geometry.attributes.position.array.map((element: any, index: number) => {
          if (index % 3 === 0) return element;
          if (index % 3 === 1) return element;
          if (index % 3 === 2) return element;
        }),
      );

      const x = pieceBox.max.x - pieceBox.min.x; // largeur
      const y = pieceBox.max.y - pieceBox.min.y; // hauteur
      const z = pieceBox.max.z - pieceBox.min.z; // profondeur
      const size = x * y * z;

      const { ttc, ht, purchase } = getPiecePrice(activePieces[elIndex], x, y, z, elIndex);

      return {
        underAssembly: activePieces[elIndex]?.underAssembly,
        geometry: el.geometry,
        position: el.position,
        name: activePieces[elIndex]?.name,
        size,
        granit: activePieces[elIndex]?.granit,
        ttcPrice: ttc,
        htPrice: ht,
        purchasePrice: purchase,
        type: activePieces[elIndex].type as PieceType,
        x,
        y,
        z,
      };
    });

    const underAssemblyPieces = piecesDetails.reduce((acc: any, piece) => {
      if (piece.underAssembly) {
        const uuid = piece.underAssembly.uuid;
        if (!acc[uuid]) {
          acc[uuid] = {
            underAssemblyUUID: uuid,
            underAssemblyName: piece.underAssembly.name,
            pieces: [],
          };
        }
        acc[uuid].pieces.push(piece);
      }
      return acc;
    }, {});

    const underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
    }[] = Object.values(underAssemblyPieces);

    const simplePieces: PieceDetail[] = piecesDetails.filter(
      (piece) => !piece.underAssembly && !isAccesory(piece.type),
    );
    const accesories: PieceDetail[] = piecesDetails.filter(
      (piece) => !piece.underAssembly && isAccesory(piece.type),
    );
    const stelesDetails: PieceDetail[] = customSteles.map((el, elIndex) => {
      const geometry = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);

      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        (geometry.attributes.position as any).array.map((element: any, index: number) => {
          if (index % 3 === 0) return element;
          if (index % 3 === 1) return element;
          if (index % 3 === 2) return element;
        }),
      );

      const x = pieceBox.max.x - pieceBox.min.x;
      const y = pieceBox.max.y - pieceBox.min.y;
      const z = pieceBox.max.z - pieceBox.min.z;
      const size = x * y * z;

      const { ttc, ht, purchase } = getPiecePrice(
        {
          polishingX: el.additionalPriceForCustomStele?.polishingX ?? 0,
          polishingY: el.additionalPriceForCustomStele?.polishingY ?? 0,
          polishingZ: el.additionalPriceForCustomStele?.polishingZ ?? 0,
          sawingX: el.additionalPriceForCustomStele?.sawingX ?? 0,
          sawingY: el.additionalPriceForCustomStele?.sawingY ?? 0,
          sawingZ: el.additionalPriceForCustomStele?.sawingZ ?? 0,
          importExtra: 0,
          frenchExtra: el.additionalPriceForCustomStele?.frenchExtra ?? 0,
          granit: el.granit,
          frenchLinearDetails: {
            faceX: el.additionalPriceForCustomStele?.frenchLinearDetails.faceX ?? 0,
            faceX2: el.additionalPriceForCustomStele?.frenchLinearDetails.faceX2 ?? 0,
            faceY: el.additionalPriceForCustomStele?.frenchLinearDetails.faceY ?? 0,
            faceY2: el.additionalPriceForCustomStele?.frenchLinearDetails.faceY2 ?? 0,
            faceZ: el.additionalPriceForCustomStele?.frenchLinearDetails.faceZ ?? 0,
            faceZ2: el.additionalPriceForCustomStele?.frenchLinearDetails.faceZ2 ?? 0,
          },
          frenchM2Details: {
            faceX: el.additionalPriceForCustomStele?.frenchM2Details.faceX ?? 0,
            faceX2: el.additionalPriceForCustomStele?.frenchM2Details.faceX2 ?? 0,
            faceY: el.additionalPriceForCustomStele?.frenchM2Details.faceY ?? 0,
            faceY2: el.additionalPriceForCustomStele?.frenchM2Details.faceY2 ?? 0,
            faceZ: el.additionalPriceForCustomStele?.frenchM2Details.faceZ ?? 0,
            faceZ2: el.additionalPriceForCustomStele?.frenchM2Details.faceZ2 ?? 0,
          },
          importLinearDetails: {
            faceX: 0,
            faceX2: 0,
            faceY: 0,
            faceY2: 0,
            faceZ: 0,
            faceZ2: 0,
          },
          importM2Details: {
            faceX: 0,
            faceX2: 0,
            faceY: 0,
            faceY2: 0,
            faceZ: 0,
            faceZ2: 0,
          },
          type: 'STELE',
        },
        x,
        y,
        z,
      );
      return {
        name: `Stèle personnalisée ${elIndex + 1}`,
        size,
        geometry,
        granit: el?.granit,
        position: el.position,
        ttcPrice: ttc,
        htPrice: ht,
        purchasePrice: purchase,
        type: 'STELE',
        x,
        y,
        z,
      };
    });
    const platingsDetails: PieceDetail[] = customPlatings
      .filter((el) => el.isActive)
      .map((el, elIndex) => {
        const geometry = new THREE.ExtrudeGeometry(el.shape, {
          curveSegments: 12,
          steps: 2,
          depth: el.shapeDetails.depth,
          bevelEnabled: false,
        });

        const pieceBox = new Box3().setFromArray(
          // eslint-disable-next-line array-callback-return
          (geometry.attributes.position as any).array.map((element: any, index: number) => {
            if (index % 3 === 0) return element;
            if (index % 3 === 1) return element;
            if (index % 3 === 2) return element;
          }),
        );

        const x = pieceBox.max.x - pieceBox.min.x;
        const y = pieceBox.max.y - pieceBox.min.y;
        const z = pieceBox.max.z - pieceBox.min.z;
        const size = x * y * z;

        const { ttc, ht, purchase } = getPiecePrice(
          {
            polishingX: 1,
            polishingY: 1,
            polishingZ: 1,
            sawingX: 2,
            sawingY: 2,
            sawingZ: 2,
            importExtra: 0,
            frenchExtra: 0,
            granit: el.granit,
            frenchLinearDetails: {
              faceX: 0,
              faceX2: 0,
              faceY: 0,
              faceY2: 0,
              faceZ: 0,
              faceZ2: 0,
            },
            frenchM2Details: {
              faceX: 0,
              faceX2: 0,
              faceY: 0,
              faceY2: 0,
              faceZ: 0,
              faceZ2: 0,
            },
            importLinearDetails: {
              faceX: 0,
              faceX2: 0,
              faceY: 0,
              faceY2: 0,
              faceZ: 0,
              faceZ2: 0,
            },
            importM2Details: {
              faceX: 0,
              faceX2: 0,
              faceY: 0,
              faceY2: 0,
              faceZ: 0,
              faceZ2: 0,
            },
            type: 'PLATING',
          },
          x,
          y,
          z,
        );

        const getName = (key: 'front' | 'back' | 'left' | 'right') => {
          // eslint-disable-next-line default-case
          switch (key) {
            case 'front': {
              return 'avant';
            }
            case 'back': {
              return 'arrière';
            }
            case 'left': {
              return 'gauche';
            }
            case 'right': {
              return 'droit';
            }
          }
        };
        return {
          name: `Placage ${getName(el.key)}`,
          size,
          geometry,
          position: el.position,
          granit: el?.granit,
          ttcPrice: ttc,
          htPrice: ht,
          purchasePrice: purchase,
          type: 'PLATING',
          x,
          y,
          z,
        };
      });

    const underAssembliesWithSize = underAssemblies.map((underAssembly) => {
      const globalBox = new Box3();

      for (const [elIndex, el] of underAssembly.pieces.entries()) {
        const pieceBox = new Box3().setFromArray(
          // eslint-disable-next-line array-callback-return
          el.geometry.attributes.position.array.map((element: any, index: number) => {
            if (index % 3 === 0) return element + el.position.x;
            if (index % 3 === 1) return element + el.position.y;
            if (index % 3 === 2) return element + el.position.z;
          }),
        );

        globalBox.union(pieceBox);
      }

      const x = Number.parseFloat((globalBox.max.x - globalBox.min.x).toFixed(4));
      const y = Number.parseFloat((globalBox.max.y - globalBox.min.y).toFixed(4));
      const z = Number.parseFloat((globalBox.max.z - globalBox.min.z).toFixed(4));
      const size = x * y * z;

      return {
        pieces: underAssembly.pieces,
        underAssemblyName: underAssembly.underAssemblyName,
        underAssemblyUUID: underAssembly.underAssemblyUUID,
        x,
        y,
        z,
        size,
      };
    });

    let patternsDetails: any[] = [];

    // eslint-disable-next-line guard-for-in
    for (const key1 in patterns) {
      // eslint-disable-next-line guard-for-in, @typescript-eslint/no-for-in-array
      for (const key2 in patterns[key1]) {
        const elements = patterns[key1][key2].elements;
        patternsDetails = [...patternsDetails, ...elements];
      }
    }

    const filteredPatterns = patternsDetails.filter((el) => el.type === 'image');
    const mapedPatterns = filteredPatterns.map((el) => {
      const defaultM3 = el.default.x * el.default.y;
      const actualM3 = el.scale.x * el.scale.y;

      let coeff = actualM3 / defaultM3;

      if (actualM3 < defaultM3) coeff = 1;

      let ttcPrice = Math.ceil(el.price * coeff);
      let htPrice = 0;

      // If it's a pattern created by an admin, we apply the coefficients
      if (el.user?.role === 'ADMIN') {
        ttcPrice = ttcPrice * (assemblyUser?.userCoefficients?.patterns ?? 1) * 1.2;
        htPrice = Math.ceil(el.price * coeff);
      }

      if (el.user?.role !== 'ADMIN' && el.leaderGranitEngraving) {
        htPrice = 0;
        // TODO add the price of the engraving
      }

      return {
        ...el,
        ttcPrice,
        htPrice,
      };
    });
    const engravings = patternsDetails.filter((el) => el.type === 'text');
    const mapedEngravings = engravings.map((el) => {
      const getPriceWithSize = (arrayOfPrice: any, size: number) => {
        if (size === 1) return arrayOfPrice.coeff1;
        if (size === 2) return arrayOfPrice.coeff2;
        if (size === 3) return arrayOfPrice.coeff3;
        if (size === 4) return arrayOfPrice.coeff4;
        if (size === 5) return arrayOfPrice.coeff5;
        if (size === 6) return arrayOfPrice.coeff6;
        return arrayOfPrice.coeff7;
      };

      const elPrice =
        (el.color === '#FFFFFF'
          ? getPriceWithSize(assemblyUser?.userCoefficients?.engravingWhite ?? 0, +el.fontSize)
          : getPriceWithSize(assemblyUser?.userCoefficients?.engravingGold ?? 0, +el.fontSize)) *
          el?.text?.replace(/ /g, '').length ?? 1;

      let buyPrice = 0;
      if (adminEngravingsPrice) {
        buyPrice =
          (el.color === '#FFFFFF'
            ? getPriceWithSize(adminEngravingsPrice.white, +el.fontSize)
            : getPriceWithSize(adminEngravingsPrice.gold, +el.fontSize)) *
            el?.text?.replace(/ /g, '').length ?? 1;
      }

      return {
        ...el,
        ttcPrice: Math.ceil(elPrice),
        htPrice: Math.ceil(buyPrice),
      };
    });

    return {
      underAssemblies: underAssembliesWithSize,
      pieces: simplePieces,
      steles: stelesDetails,
      accesories,
      patterns: mapedPatterns,
      engravings: mapedEngravings,
      platings: platingsDetails,
    };
  };

  const getAssemblySize = (wihoutSole = false) => {
    const globalBox = new THREE.Box3(new Vector3(0, 0, 0), new Vector3(0, 0, 0));

    for (const [elIndex, el] of customSteles.entries()) {
      const geometry = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);

      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        (geometry.attributes.position as any).array.map((element: any, index: number) => {
          if (index % 3 === 0) return element + el.position.x;
          if (index % 3 === 1) return element + el.position.y;
          if (index % 3 === 2) return element + el.position.z;
        }),
      );
      globalBox.union(pieceBox);
    }

    for (const [elIndex, el] of customPlatings.filter((el) => el.isActive).entries()) {
      const geometry = new THREE.ExtrudeGeometry(el.shape, {
        curveSegments: 12,
        steps: 2,
        depth: el.shapeDetails.depth,
        bevelEnabled: false,
      });

      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        (geometry.attributes.position as any).array.map((element: any, index: number) => {
          if (index % 3 === 0) return element + el.position.x;
          if (index % 3 === 1) return element + el.position.y;
          if (index % 3 === 2) return element + el.position.z;
        }),
      );

      globalBox.union(pieceBox);
    }

    const piecesToUse = wihoutSole
      ? pieces.filter((el, elIndex) => activePieces[elIndex].type !== 'SOLE')
      : pieces;

    for (const [elIndex, el] of piecesToUse.entries()) {
      const pieceBox = new Box3().setFromArray(
        // eslint-disable-next-line array-callback-return
        el.geometry.attributes.position.array.map((element: any, index: number) => {
          if (index % 3 === 0) return element + el.position.x;
          if (index % 3 === 1) return element + el.position.y;
          if (index % 3 === 2) return element + el.position.z;
        }),
      );

      globalBox.union(pieceBox);
    }

    const x = Number.parseFloat((globalBox.max.x - globalBox.min.x).toFixed(4));
    const z = Number.parseFloat((globalBox.max.z - globalBox.min.z).toFixed(4));

    return {
      width: x,
      depth: z,
    };
  };

  return {
    geometry: pieces[pieceIndex] ? pieces[pieceIndex].geometry.attributes.position.array : [],
    selectedPoints,
    constraints,
    userModifications,
    history,
    pieces,
    pieceIndex,
    reloadPiece,
    customSteles,
    activePieces,
    customPlatings,
    granits,
    patterns,
    setSelectedPoints,
    changePoint,
    refreshConstraints,
    refreshModifications,
    setPieceIndex,
    setReloadPiece,
    changeGeometry,
    setActivePieces,
    changePosition,
    setCustomSteles,
    removeOnePiece,
    movePieceOnAxe,
    checkCollision,
    setCustomPlatings,
    findPointOnTheSameAxe,
    findPointAround,
    refreshGranits,
    getAssemblyDetails,
    setPatterns,
    getAssemblySize,
    centerCamera,
  };
};
